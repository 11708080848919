const HORARIOS = [
  "07:00", "07:15", "07:30", "07:45",
  "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45",
  "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45",
  "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45",
  "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45",
  "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45",
  "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:15",
  "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45",
  "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45",
  "00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45",
  "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45",
  "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45",
  "06:00", "06:15", "06:30", "06:45",
];

const CORES = [
  "#98ddca",
  "#d5ecc2",
  "#f39189",
  "#798777",
  "#865858",
  "#ff7171",
  "#9fd8df",
];

const CONVENIO = [
  "Amil",
  "Allianz",
  "Bradesco Saúde",
  "Golden Cross",
  "Notre Dame Intermédica",
  "Porto Seguro",
  "Sul América",
  "Unimed",
]

const ALERGIAS = [
  "Amendoim",
  "Frutos do Mar",
  "Glúten",
  "Ovo",
  "Proteína do leite de vaca",
  "Soja",
  "Trigo",
]

const SEMANAS_GESTACIONAIS = [
  { id: "0", semana: 1, descricao: "1ª Semana" },
  { id: "1", semana: 2, descricao: "2ª Semana" },
  { id: "2", semana: 3, descricao: "3ª Semana" },
  { id: "3", semana: 4, descricao: "4ª Semana" },
  { id: "4", semana: 5, descricao: "5ª Semana" },
  { id: "5", semana: 6, descricao: "6ª Semana" },
  { id: "6", semana: 7, descricao: "7ª Semana" },
  { id: "7", semana: 8, descricao: "8ª Semana" },
  { id: "8", semana: 9, descricao: "9ª Semana" },
  { id: "9", semana: 10, descricao: "10ª Semana" },
  { id: "10", semana: 11, descricao: "11ª Semana" },
  { id: "11", semana: 12, descricao: "12ª Semana" },
  { id: "12", semana: 13, descricao: "13ª Semana" },
  { id: "13", semana: 14, descricao: "14ª Semana" },
  { id: "14", semana: 15, descricao: "15ª Semana" },
  { id: "15", semana: 16, descricao: "16ª Semana" },
  { id: "16", semana: 17, descricao: "17ª Semana" },
  { id: "17", semana: 18, descricao: "18ª Semana" },
  { id: "18", semana: 19, descricao: "19ª Semana" },
  { id: "19", semana: 20, descricao: "20ª Semana" },
  { id: "20", semana: 21, descricao: "21ª Semana" },
  { id: "21", semana: 22, descricao: "22ª Semana" },
  { id: "22", semana: 23, descricao: "23ª Semana" },
  { id: "23", semana: 24, descricao: "24ª Semana" },
  { id: "24", semana: 25, descricao: "25ª Semana" },
  { id: "25", semana: 26, descricao: "26ª Semana" },
  { id: "26", semana: 27, descricao: "27ª Semana" },
  { id: "27", semana: 28, descricao: "28ª Semana" },
  { id: "28", semana: 29, descricao: "29ª Semana" },
  { id: "29", semana: 30, descricao: "30ª Semana" },
  { id: "30", semana: 31, descricao: "31ª Semana" },
  { id: "31", semana: 32, descricao: "32ª Semana" },
  { id: "32", semana: 33, descricao: "33ª Semana" },
  { id: "33", semana: 34, descricao: "34ª Semana" },
  { id: "34", semana: 35, descricao: "35ª Semana" },
  { id: "35", semana: 36, descricao: "36ª Semana" },
  { id: "36", semana: 37, descricao: "37ª Semana" },
  { id: "37", semana: 38, descricao: "38ª Semana" },
  { id: "38", semana: 39, descricao: "39ª Semana" },
  { id: "39", semana: 40, descricao: "40ª Semana" },
  { id: "40", semana: 41, descricao: "41ª Semana" },
  { id: "41", semana: 42, descricao: "42ª Semana" },
  { id: "42", semana: 43, descricao: "43ª Semana" },
]

const PERFIS = [
  { id: 1, descricao: 'Administrador' },
  { id: 2, descricao: 'Secretaria' },
  { id: 3, descricao: 'Nutricionista' }
];


const ROTAS_ACESSO = {
  AGENDA: {
    descricao: "Agenda",
    perfis: [1, 2, 3]
  },
  PACIENTES: {
    descricao: "Pacientes",
    perfis: [1, 2, 3]
  },
  USUARIOS: {
    descricao: "Usuarios",
    perfis: [1]
  }
};

const PLANOS = {
  PROFISSIONAL: {
    MENSAL: { ID: 1, DESCRICAO: 'Profissional Mensal' },
    TRIMESTRAL: { ID: 2, DESCRICAO: 'Profissional Trimestral' },
    SEMESTRAL: { ID: 3, DESCRICAO: 'Profissional Semestral' }
  },
  CLINICA_BASICO: {
    MENSAL: { ID: 4, DESCRICAO: 'Clínica Básico Mensal' },
    TRIMESTRAL: { ID: 5, DESCRICAO: 'Clínica Básico Trimestral' },
    SEMESTRAL: { ID: 6, DESCRICAO: 'Clínica Básico Semestral' },
  },
  CLINICA_PADRAO: {
    MENSAL: { ID: 7, DESCRICAO: 'Clínica Padrão Mensal' },
    TRIMESTRAL: { ID: 8, DESCRICAO: 'Clínica Padrão Trimestral' },
    SEMESTRAL: { ID: 9, DESCRICAO: 'Clínica Padrão Semestral' },
  },
  CLINICA_COMPLETO: {
    MENSAL: { ID: 7, DESCRICAO: 'Clínica Completo Mensal' },
    TRIMESTRAL: { ID: 8, DESCRICAO: 'Clínica Completo Semestral' },
    SEMESTRAL: { ID: 9, DESCRICAO: 'Clínica Completo Anual' },
  }
};

const LISTA_PLANOS = [
  PLANOS.PROFISSIONAL.MENSAL,
  PLANOS.PROFISSIONAL.TRIMESTRAL,
  PLANOS.PROFISSIONAL.SEMESTRAL,
  PLANOS.CLINICA_BASICO.MENSAL,
  PLANOS.CLINICA_BASICO.TRIMESTRAL,
  PLANOS.CLINICA_BASICO.SEMESTRAL,
  PLANOS.CLINICA_PADRAO.MENSAL,
  PLANOS.CLINICA_PADRAO.TRIMESTRAL,
  PLANOS.CLINICA_PADRAO.SEMESTRAL,
  PLANOS.CLINICA_COMPLETO.MENSAL,
  PLANOS.CLINICA_COMPLETO.TRIMESTRAL,
  PLANOS.CLINICA_COMPLETO.SEMESTRAL
]

const LISTA_GRAFICOS_INFANTIS = [
  { id: 1, descricao: 'IMC para Idade' },
  { id: 2, descricao: 'Estatura para Idade' },
  { id: 3, descricao: 'Peso para Idade' },
  { id: 4, descricao: 'Peso para Estatura' }
];


export {
  CONVENIO,
  ALERGIAS,
  HORARIOS,
  CORES,
  SEMANAS_GESTACIONAIS,
  PERFIS,
  ROTAS_ACESSO,
  PLANOS,
  LISTA_PLANOS,
  LISTA_GRAFICOS_INFANTIS
}