import Login from '@/views/Login.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';
import ControleDePaciente from '@/views/paciente/ControleDePaciente.vue';
import ProntuarioPaciente from '@/views/paciente/ProntuarioPaciente.vue';
import Agenda from '@/views/agenda/Agenda.vue';
import ControleDeUsuario from '@/views/usuario/ControleDeUsuario.vue'
import Assinatura from '@/views/assinatura/Assinatura.vue'
import ConfirmacaoAssinatura from '@/views/assinatura/ConfirmacaoAssinatura.vue'

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    title: "Login",
    meta: { requireAuth: false }
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    title: "Dashboard",
    meta: { requireAuth: true }
  },
  {
    path: "/controle-paciente/:id/prontuario",
    name: "prontuario-paciente",
    component: ProntuarioPaciente,
    title: "Prontuario Paciente",
    meta: { requireAuth: true }
  },
  {
    path: "/controle-paciente",
    name: "ControleDePaciente",
    component: ControleDePaciente,
    title: "Controle de Paciente",
    meta: { requireAuth: true }
  },
  {
    path: "/agenda",
    name: "Agenda",
    component: Agenda,
    title: "agenda",
    meta: { requireAuth: true }
  },
  {
    path: "/assinatura/nova-assinatura",
    name: "Assinatura",
    component: Assinatura,
    title: "Assinatura",
    icon: "",
    meta: {},
  },
  {
    path: "/assinatura/nova-assinatura/confirmacao",
    name: "ConfirmacaoAssinatura",
    component: ConfirmacaoAssinatura,
    title: "Confirmação assinatura",
    icon: "",
    meta: {},
  },
  {
    path: "/controle-usuario",
    name: "ControleDeUsuario",
    component: ControleDeUsuario,
    title: "Controle de Usuário",
    meta: { requireAuth: true }
  },
];

export default routes;