<template>
  <v-card flat>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="5000"
      top="top"
      :color="tipo"
      elevation="0"
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row v-show="!this.antropometrias.length" class="sem-registro">
      <TelaVazia
        :imagemSrc="'antropometria-infantil.svg'"
        :titulo="'Não há registro de antropometria infantil'"
        :subtitulo="'Adicione um paciente para iniciar um atendimento'"
        :textoBotaoPrimario="'Adicionar antropometrias'"
        :exibirBotaoPrimario="true"
        :eventoBotaoPrimario="adicionarAntropometria"
      >
      </TelaVazia>
    </v-row>
    <v-card-text v-show="this.antropometrias.length">
      <v-row>
        <v-col cols="12" lg="9">
          <v-btn class="btn-primario mr-2" text @click="adicionarAntropometria">
            <plus-icon size="1x" class="mr-2"></plus-icon>Antropometria
          </v-btn>
        </v-col>
        <v-col cols="12" lg="3">
          <v-select
            v-model="graficoSelecionado"
            return-object
            :items="listaDeGraficosInfantis"
            item-text="descricao"
            label="Selecione o gráfico de referência"
            prepend-icon="mdi-chart-areaspline"
          ></v-select>
        </v-col>
      </v-row>
      <!-- Antropometrias -->
      <v-row>
        <v-col cols="12" lg="7">
          <v-data-table
            dense
            :items="antropometrias"
            :headers="colunasAntropometrias"
            :items-per-page="10"
            :sort-by.sync="ordenacaoTabelaAntropometria"
            :search="search"
            class="elevation-0"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td v-if="item.idadeMeses == 1" class="text-left">
                    {{ item.idadeMeses }} mês
                  </td>
                  <td v-else class="text-left">{{ item.idadeMeses }} meses</td>
                  <td class="text-center">{{ item.peso }} Kg</td>
                  <td class="text-center">
                    {{ item.altura }}
                  </td>
                  <td class="text-center">
                    {{ item.imc }}
                  </td>
                  <td class="text-right">
                    {{ item.dataMarcacao | data }}
                  </td>
                  <td class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          @click="editarAntropometria(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <edit-2-icon size="1.1x"></edit-2-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="excluir(item)"
                        >
                          <trash-icon size="1.1x"></trash-icon>
                        </v-btn>
                      </template>
                      <span>Excluir</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-if="graficoSelecionado.id == 1" cols="12" lg="5">
          <AntropometriaImcParaIdade
            :titulo="graficoSelecionado.descricao"
            :idade="dadosAntropometriaInfantilTabela.MESES"
            :magrezaAcentuada="
              dadosAntropometriaInfantilTabela.MAGREZA_ACENTUADA
            "
            :magreza="dadosAntropometriaInfantilTabela.MAGREZA"
            :eutrofia="dadosAntropometriaInfantilTabela.EUTROFIA"
            :riscoSobrePeso="dadosAntropometriaInfantilTabela.RISCO_SOBREPESO"
            :sobrepeso="dadosAntropometriaInfantilTabela.SOBREPESO"
            :obesidade="dadosAntropometriaInfantilTabela.OBESIDADE"
            :curva-paciente="curvaPaciente"
            :filtro-valores-eixo-x="filtroValoresEixoX"
            :key="graficoKey"
          ></AntropometriaImcParaIdade>
        </v-col>
        <v-col v-if="graficoSelecionado.id == 2" cols="12" lg="5">
          <AntropometriaEstaturaParaIdade
            :titulo="graficoSelecionado.descricao"
            :idade="dadosAntropometriaInfantilTabela.MESES"
            :muitoBaixaEstatura="
              dadosAntropometriaInfantilTabela.MUITO_BAIXA_ESTATURA_PARA_IDADE
            "
            :baixaEstatura="
              dadosAntropometriaInfantilTabela.BAIXA_ESTATURA_PARA_IDADE
            "
            :estaturaAdequada="
              dadosAntropometriaInfantilTabela.ESTATURA_ADEQUADA_PARA_IDADE
            "
            :curva-paciente="curvaPaciente"
            :filtro-valores-eixo-x="filtroValoresEixoX"
            :key="graficoKey"
          ></AntropometriaEstaturaParaIdade>
        </v-col>
        <v-col v-if="graficoSelecionado.id == 3" cols="12" lg="5">
          <AntropometriaPesoParaIdade
            :titulo="graficoSelecionado.descricao"
            :idade="dadosAntropometriaInfantilTabela.MESES"
            :muitoBaixoPeso="
              dadosAntropometriaInfantilTabela.MUITO_BAIXO_PESO_PARA_IDADE
            "
            :baixoPeso="dadosAntropometriaInfantilTabela.BAIXO_PESO_PARA_IDADE"
            :pesoAdequado="
              dadosAntropometriaInfantilTabela.PESO_ADEQUADO_PARA_IDADE
            "
            :pesoElevado="
              dadosAntropometriaInfantilTabela.PESO_ELEVADO_PARA_IDADE
            "
            :curva-paciente="curvaPaciente"
            :filtro-valores-eixo-x="filtroValoresEixoX"
            :key="graficoKey"
          ></AntropometriaPesoParaIdade>
        </v-col>
        <v-col v-if="graficoSelecionado.id == 4" cols="12" lg="5">
          <AntropometriaPesoParaEstatura
            :titulo="graficoSelecionado.descricao"
            :idade="dadosAntropometriaInfantilTabela.ESTATURA"
            :magrezaAcentuada="
              dadosAntropometriaInfantilTabela.MAGREZA_ACENTUADA
            "
            :magreza="dadosAntropometriaInfantilTabela.MAGREZA"
            :eutrofia="dadosAntropometriaInfantilTabela.EUTROFIA"
            :riscoSobrePeso="dadosAntropometriaInfantilTabela.RISCO_SOBREPESO"
            :sobrepeso="dadosAntropometriaInfantilTabela.SOBREPESO"
            :obesidade="dadosAntropometriaInfantilTabela.OBESIDADE"
            :curva-paciente="curvaPaciente"
            :filtro-valores-eixo-x="filtroValoresEixoX"
            :key="graficoKey"
          ></AntropometriaPesoParaEstatura>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Cadastrar Antropometria -->
    <!-- TODO organiza campos na tela -->
    <v-dialog eager max-width="550px" v-model="modalAdicionarAntropometria">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">
            {{
              modoEdicao
                ? "Editar avaliação antropométrica"
                : "Nova avaliação antropométrica"
            }}
          </h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-row class="pt-3">
            <v-col cols="12" lg="12">
              <h2 class="pa-3 text-center">
                Preencha as informações abaixo para realizar uma avaliação
                antropométrica
              </h2>
            </v-col>
            <v-col cols="12" lg="6">
              <date-picker
                label="Data marcação"
                data-minima="1900-01-01"
                :dataMaxima="new Date().toString()"
                dense
                v-model="antropometria.dataMarcacao"
                @onChange="verificaIdadePorDataMarcacao"
              >
              </date-picker>
            </v-col>
            <v-col cols="12" lg="3">
              <CampoPeso
                label="Peso"
                dense
                v-model="antropometria.peso"
                suffix="Kg"
                required
              >
              </CampoPeso>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field
                input-mode="decimal"
                label="Altura"
                v-model="antropometria.altura"
                v-mask="'#.##'"
                dense
              >
              </v-text-field>
              <v-text-field
                input-mode="decimal"
                label="Idade"
                suffix="meses"
                disabled
                v-model="antropometria.idadeMeses"
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!modoEdicao"
            class="btn-primario"
            text
            @click="cadastrarAntropometria"
          >
            Salvar
          </v-btn>
          <v-btn
            v-if="modoEdicao"
            class="btn-primario"
            text
            @click="atualizarAntropometria"
          >
            Atualizar
          </v-btn>
          <v-btn
            @click="fecharModalAdicionarAntropometria"
            class="btn-terciario"
            text
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <janela-confirmacao
      v-model="abrirJanelaConfirmacao"
      :callbackPrimario="callbackExcluirantropometria"
      :mensagem="mensagemDeExclusao"
    />
  </v-card>
</template>

<script>
// Componentes
import CampoPeso from "@/components/comum/CampoPeso";
import DatePicker from "@/components/comum/Datepicker";
import JanelaConfirmacao from "@/components/comum/JanelaConfirmacao";
import AntropometriaImcParaIdade from "@/components/graficos/AntropometriaImcParaIdade.vue";
import AntropometriaEstaturaParaIdade from "@/components/graficos/AntropometriaEstaturaParaIdade.vue";
import AntropometriaPesoParaIdade from "@/components/graficos/AntropometriaPesoParaIdade.vue";
import AntropometriaPesoParaEstatura from "@/components/graficos/AntropometriaPesoParaEstatura.vue";
import TelaVazia from "@/components/comum/TelaVazia";

// Models
import AntropometriaInfantilModel from "@/model/antropometria/antropometria-infantil-model";

// Utils
import utilsData from "@/utils/data";
import utilsCalculos from "@/utils/calculos";

// API
import apiAntropometria from "@/api/antropometria/antropometria-infantil-api";

import moment from "moment";

// Mixin
import MixinMensagem from "@/mixin/mixin-mensagem";

// import { TABELA_DIAGNOSTICO_NUTRICIONAL } from "../../../../constants/tabelas/tabela-diagnostico-nutricional/tabela-diagnostico-nutricional";
import { DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL } from "@/constants/antropometrias-infantil-masculino";
import { DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL } from "@/constants/antropometrias-infantil-feminina";
import { LISTA_GRAFICOS_INFANTIS } from "@/constants/geral-constantes";

export default {
  // name: "AntropometriaInfantilTab",
  mixins: [MixinMensagem],
  name: "AntropometriaInfantilTab",

  components: {
    CampoPeso,
    DatePicker,
    JanelaConfirmacao,
    AntropometriaImcParaIdade,
    AntropometriaEstaturaParaIdade,
    AntropometriaPesoParaIdade,
    AntropometriaPesoParaEstatura,
    TelaVazia,
  },
  props: {
    paciente: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      listaDeGraficosInfantis: LISTA_GRAFICOS_INFANTIS,
      mensagemDeExclusao: undefined,
      antropometrias: [],
      modalAdicionarAntropometria: false,
      modoEdicao: false,
      antropometria: new AntropometriaInfantilModel(),
      search: "",
      graficoSelecionado: LISTA_GRAFICOS_INFANTIS[0],
      ordenacaoTabelaAntropometria: "idadeMeses",
      colunasAntropometrias: [
        {
          text: "Idade",
          value: "idadeMeses",
          sortable: true,
          align: "left",
        },
        { text: "Peso", value: "peso", sortable: false, align: "center" },
        { text: "Altura", value: "altura", sortable: false, align: "center" },
        { text: "IMC", value: "imc", sortable: false, align: "center" },
        {
          text: "Data de Marcação",
          value: "dataMarcacao",
          sortable: false,
          align: "right",
        },
        { text: "", value: "botoes", sortable: false, align: "right" },
      ],
      antropometriaExclusao: undefined,
      abrirJanelaConfirmacao: false,
      dadosAntropometriaInfantilTabela: {},
      curvaPaciente: [],
      graficoKey: 0,
    };
  },
  mounted() {
    this.obterAntropometriasPaciente();

    debugger; // eslint-disable-line no-debugger
    // var percentil = this.obterPercential(2, 1, 11.0);
    // var diagnostico = this.obterDiagnosticoNutricional(percentil);
    // console.log(percentil + " " + diagnostico);
  },
  computed: {},

  watch: {
    paciente() {
      this.obterAntropometriasPaciente();
    },
    graficoSelecionado() {
      // debugger; // eslint-disable-line no-debugger
      console.log("ID ", this.graficoSelecionado.id);
      this.preencherCurvaPaciente();
      // this.dadosAntropometriaInfantilTabela =
      //   this.obterDadosAntropometriaInfantilTabela();
      // this.graficoKey += 1;
    },
  },

  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },

    idade(dataNascimento) {
      return utilsData.calculaIdade(dataNascimento);
    },
  },
  methods: {
    obterDadosAntropometriaInfantilTabela() {
      let anos = utilsData.calculaIdade(this.paciente.dataNascimento);
      let meses = utilsData.calcularIdadeMes(this.paciente.dataNascimento);
      let idade = parseFloat(anos + "." + meses);

      return this.paciente.sexo == "M"
        ? this.dadosAntropometriaInfantilMasculino(idade)
        : this.dadosAntropometriaInfantilFeminina(idade);
    },

    obterDadosImcParaIdadeInfantilMasculino(idade) {
      if (idade <= 2)
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.IMC_PARA_IDADE
          .INFANTIL_MASCULINO_IMC_PARA_IDADE_0_ATE_2_ANOS;
      else if (idade <= 5)
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.IMC_PARA_IDADE
          .INFANTIL_MASCULINO_IMC_PARA_IDADE_2_ATE_5_ANOS;
      else
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.IMC_PARA_IDADE
          .INFANTIL_MASCULINO_IMC_PARA_IDADE_5_ATE_19_ANOS;
    },
    obterDadosPesoParaIdadeInfantilMasculino(idade) {
      if (idade <= 5)
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.PESO_PARA_IDADE
          .INFANTIL_MASCULINO_PESO_PARA_IDADE_0_ATE_5_ANOS;
      else
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.PESO_PARA_IDADE
          .INFANTIL_MASCULINO_PESO_PARA_IDADE_5_ATE_10_ANOS;
    },
    obterDadosEstaturaParaIdadeInfantilMasculino(idade) {
      if (idade <= 2)
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.ESTATURA_PARA_IDADE
          .INFANTIL_MASCULINO_COMPRIMENTO_PARA_IDADE_0_ATE_2_ANOS;
      else if (idade <= 5)
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.ESTATURA_PARA_IDADE
          .INFANTIL_MASCULINO_ESTATURA_PARA_IDADE_2_ATE_5_ANOS;
      else
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.ESTATURA_PARA_IDADE
          .INFANTIL_MASCULINO_ESTATURA_PARA_IDADE_5_ATE_19_ANOS;
    },
    obterDadosPesoParaEstaturaInfantilMasculino(idade) {
      if (idade <= 2)
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.PESO_PARA_ESTATURA
          .INFANTIL_MASCULINO_PESO_PARA_COMPRIMENTO_0_ATE_2_ANOS;
      else
        return DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL.PESO_PARA_ESTATURA
          .INFANTIL_MASCULINO_PESO_PARA_ESTATURA_2_ATE_5_ANOS;
    },
    obterDadosEstaturaParaIdadeInfantilFeminina(idade) {
      if (idade <= 2)
        return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.ESTATURA_PARA_IDADE
          .INFANTIL_FEMININA_COMPRIMENTO_PARA_IDADE_0_ATE_2_ANOS;
      else if (idade <= 5)
        return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.ESTATURA_PARA_IDADE
          .INFANTIL_FEMININA_ESTATURA_PARA_IDADE_2_ATE_5_ANOS;
      else
        return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.ESTATURA_PARA_IDADE
          .INFANTIL_FEMININA_ESTATURA_PARA_IDADE_5_ATE_19_ANOS;
    },
    obterDadosImcParaIdadeInfantilFeminina(idade) {
      if (idade <= 2)
        return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.IMC_PARA_IDADE
          .INFANTIL_FEMININA_IMC_PARA_IDADE_0_ATE_2_ANOS;
      else if (idade <= 5)
        return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.IMC_PARA_IDADE
          .INFANTIL_FEMININA_IMC_PARA_IDADE_2_ATE_5_ANOS;
      return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.IMC_PARA_IDADE
        .INFANTIL_FEMININA_IMC_PARA_IDADE_5_ATE_19_ANOS;
    },
    obterDadosPesoParaIdadeInfantilFeminina(idade) {
      if (idade <= 5)
        return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.PESO_PARA_IDADE
          .INFANTIL_FEMININA_PESO_PARA_IDADE_0_ATE_5_ANOS;
      else
        return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.PESO_PARA_IDADE
          .INFANTIL_FEMININA_PESO_PARA_IDADE_5_ATE_10_ANOS;
    },
    obterDadosPesoParaEstaturaInfantilFeminina(idade) {
      if (idade <= 2)
        return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.PESO_PARA_ESTATURA
          .INFANTIL_FEMININA_PESO_PARA_COMPRIMENTO_0_ATE_2_ANOS;
      else
        return DADOS_ANTROPOMETRIAS_FEMININA_INFANTIL.PESO_PARA_ESTATURA
          .INFANTIL_FEMININA_PESO_PARA_ESTATURA_2_ATE_5_ANOS;
    },
    dadosAntropometriaInfantilMasculino(idade) {
      switch (this.graficoSelecionado.id) {
        case 1:
          return this.obterDadosImcParaIdadeInfantilMasculino(idade);
        case 2:
          return this.obterDadosEstaturaParaIdadeInfantilMasculino(idade);
        case 3:
          return this.obterDadosPesoParaIdadeInfantilMasculino(idade);
        case 4:
          return this.obterDadosPesoParaEstaturaInfantilMasculino(idade);
      }
    },

    dadosAntropometriaInfantilFeminina(idade) {
      switch (this.graficoSelecionado.id) {
        case 1:
          return this.obterDadosImcParaIdadeInfantilFeminina(idade);
        case 2:
          return this.obterDadosEstaturaParaIdadeInfantilFeminina(idade);
        case 3:
          return this.obterDadosPesoParaIdadeInfantilFeminina(idade);
        case 4:
          return this.obterDadosPesoParaEstaturaInfantilFeminina(idade);
      }
    },

    preencherCurvaPaciente() {
      // Popula os dados do grafico;
      this.dadosAntropometriaInfantilTabela =
        this.obterDadosAntropometriaInfantilTabela();
      debugger; // eslint-disable-line no-debugger

      const TIPO = {
        IMC_IDADE: 1,
        ESTATURA_IDADE: 2,
        PESO_IDADE: 3,
        PESO_ESTATURA: 4,
      };

      console.log("Grafico Id: ", this.graficoSelecionado.id);

      switch (this.graficoSelecionado.id) {
        case TIPO.IMC_IDADE:
          this.curvaPaciente = this.retornarCurvaImcPorIdade();
          this.graficoKey += 1;
          break;
        case TIPO.ESTATURA_IDADE:
          this.curvaPaciente = this.retornarCurvaEstaturaPorIdade();
          this.graficoKey += 1;
          break;
        case TIPO.PESO_IDADE:
          this.curvaPaciente = this.retornarCurvaPesoPorIdade();
          this.graficoKey += 1;
          break;
        case TIPO.PESO_ESTATURA:
          this.curvaPaciente = this.retornarCurvaPesoPorEstatura();
          this.graficoKey += 1;
          break;
      }
    },

    retornarCurvaEstaturaPorIdade() {
      let curva = [];
      for (let i = 0; i < this.antropometrias.length; i++) {
        let idade = parseInt(this.antropometrias[i].idadeMeses);
        curva[idade] = this.antropometrias[i].altura;
      }
      console.log("Curva estatura por idade ", curva);
      return curva;
    },

    retornarCurvaPesoPorIdade() {
      let curva = [];
      for (let i = 0; i < this.antropometrias.length; i++) {
        let idade = parseInt(this.antropometrias[i].idadeMeses);
        curva[idade] = this.antropometrias[i].peso;
      }
      console.log("Curva peso para idade ", curva);
      return curva;
    },

    retornarCurvaPesoPorEstatura() {
      let curva = [];

      for (let i = 0; i < this.antropometrias.length; i++) {
        let estatura = parseFloat(this.antropometrias[i].altura * 100);
        curva[estatura] = this.antropometrias[i].peso;
      }
      console.log("Peso para estatura ", curva);
      return curva;
    },
    retornarCurvaImcPorIdade() {
      let curva = [];

      for (let i = 0; i < this.antropometrias.length; i++) {
        let idade = parseInt(this.antropometrias[i].idadeMeses);
        curva[idade] = this.antropometrias[i].imc;
      }

      console.log("Curva IMC ", curva);
      return curva;
    },
    filtroValoresEixoX(label) {
      if (label % 2 == 0) {
        return label;
      }
    },
    // obterDiagnosticoNutricional(percentil) {
    //   let regra = TABELA_DIAGNOSTICO_NUTRICIONAL.find((r) =>
    //     r.REGRA_PERCENTIL(percentil)
    //   );
    //   return regra.DIAGNOSTICO;
    // },

    // obterPercential(sexo, meses, imc) {
    //   return utilsCalculos.calcularPercentil(sexo, meses, imc);
    // },
    adicionarAntropometria() {
      this.modoEdicao = false;
      this.modalAdicionarAntropometria = true;
      this.limparAntropometria();
    },

    editarAntropometria(antropometria) {
      antropometria = Object.assign({}, antropometria);
      this.antropometria = new AntropometriaInfantilModel(antropometria);
      this.modoEdicao = true;
      this.modalAdicionarAntropometria = true;
    },

    fecharModalAdicionarAntropometria() {
      this.modalAdicionarAntropometria = false;
      this.limparAntropometria();
    },

    limparAntropometria() {
      this.antropometria = new AntropometriaInfantilModel();
    },

    verificaIdadePorDataMarcacao() {
      let dataNascimento = this.paciente.dataNascimento;
      let dataMarcacao = new Date(this.antropometria.dataMarcacao);

      let anos = utilsData.calculaIdade(dataNascimento, dataMarcacao);
      let meses = utilsData.calcularIdadeMes(dataNascimento, dataMarcacao);

      this.antropometria.idadeMeses = 12 * anos + meses;
    },

    excluirAntropometria(antropometria) {
      this.antropometria = new AntropometriaInfantilModel(antropometria);
      this.abrirJanelaConfirmacao = true;
      this.callbackExcluirAntropometria = this.deletarAntropometria;
      this.mensagemDeExclusao = `Deseja excluir o antropometria?`;
    },

    cadastrarAntropometria() {
      this.calcularIMCAntropometria();
      this.antropometria.consulta = this.$store.state.consulta;
      this.antropometria.paciente = this.paciente;

      apiAntropometria
        .cadastrar(this.antropometria)
        .then((resposta) => {
          this.antropometrias.push(
            new AntropometriaInfantilModel(resposta.data)
          );
          this.dispararMensagem(
            "Antropometria infantil cadastrada com sucesso!",
            "success"
          );
          this.atualizarAntropometriaNaLista(
            new AntropometriaInfantilModel(resposta.data)
          );
          this.preencherCurvaPaciente();
          this.fecharModalAdicionarAntropometria();
        })
        .catch(() => {
          this.dispararMensagem(
            "Erro no cadastro da antropometria infantil",
            "error"
          );
        });
    },

    atualizarAntropometria() {
      this.calcularIMCAntropometria();
      this.antropometria.paciente = this.paciente;

      apiAntropometria
        .atualizar(this.antropometria)
        .then((resposta) => {
          this.dispararMensagem(
            "Antropometria infantil atualizada com sucesso!",
            "success"
          );
          this.atualizarAntropometriaNaLista(
            new AntropometriaInfantilModel(resposta.data)
          );
          this.preencherCurvaPaciente();
          this.fecharModalAdicionarAntropometria();
        })
        .catch(() => {
          this.dispararMensagem(
            "Erro ao atualizar a antropometria infantil",
            "error"
          );
        });
    },

    calcularIMCAntropometria() {
      this.antropometria.peso = parseFloat(this.antropometria.peso);
      this.antropometria.altura = parseFloat(this.antropometria.altura);

      this.antropometria.imc = utilsCalculos.calcularImc(
        this.antropometria.peso,
        this.antropometria.altura
      );
    },

    atualizarAntropometriaNaLista(antropometria, deletarDaLista) {
      var index = this.antropometrias
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(antropometria.id));
      !deletarDaLista
        ? this.antropometrias.splice(index, 1, antropometria) // Atualiza
        : this.antropometrias.splice(index, 1); // Deleta
    },

    obterAntropometriasPaciente() {
      if (!this.paciente.id) {
        return;
      }
      apiAntropometria
        .obterAntropometriaPorPaciente(this.paciente.id)
        .then((resposta) => {
          this.antropometrias = resposta.data.map(
            (a) => new AntropometriaInfantilModel(a)
          );
          this.preencherCurvaPaciente();
        })
        .catch(() => {
          this.dispararMensagem(
            "Erro ao obter as antropometrias deste paciente",
            "error"
          );
        });
    },

    deletarAntropometria() {
      apiAntropometria
        .deletar(this.antropometria)
        .then(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Antropometria excluida com sucesso!",
            tipo: "success",
          });
          this.atualizarAntropometriaNaLista(this.antropometria, true);
          this.preencherCurvaPaciente();
        })
        .catch(() =>
          this.$emit("disparar-mensagem", {
            mensagem: "Falha na exclusão da antropometria",
            tipo: "error",
          })
        )
        .finally((this.abrirJanelaConfirmacao = false));
    },

    excluir(antropometria) {
      antropometria.paciente = this.paciente;
      this.antropometriaExclusao = antropometria;
      this.abrirJanelaConfirmacao = true;

      this.mensagemDeExclusao = `Deseja excluir a antropometria de idade &nbsp;<b>${antropometria.idadeMeses} meses</b>?`;
    },

    callbackExcluirantropometria() {
      apiAntropometria
        .deletar(this.antropometriaExclusao)
        .then(() => {
          this.dispararMensagem(
            "Antropometria excluida com sucesso",
            "success"
          );

          this.atualizarAntropometriaNaLista(this.antropometriaExclusao, true);
        })
        .catch(() => {
          this.dispararMensagem("Falha ao excluir antropometria", "error");
          // this.$emit('disparar-mensagem', {
          //     mensagem: 'Falha na exclusão da antropometria',
          //     tipo: 'error'
          // });
        })
        .finally(() => {
          this.antropometriaExclusao = undefined;
          this.abrirJanelaConfirmacao = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>