<template>
  <v-card flat>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="5000"
      top="top"
      :color="tipo"
      elevation="0"
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-row v-show="!this.antropometriasAdulto.length">
      <TelaVazia
        :imagemSrc="'antropometria-adulto.svg'"
        :largura="'350'"
        :titulo="'Não há registro de antropometria'"
        :subtitulo="'Adicione uma antropometria para este paciente'"
        :textoBotaoPrimario="'Adicionar antropometria'"
        :exibirBotaoPrimario="true"
        :eventoBotaoPrimario="adicionarAntropometria"
      >
      </TelaVazia>
    </v-row>

    <v-card-text v-show="this.antropometriasAdulto.length">
      <v-row class="text-left">
        <v-col cols="12" lg="12">
          <v-btn class="btn-primario mr-2" text @click="adicionarAntropometria">
            <plus-icon size="1x" class="mr-2"></plus-icon>Antropometria
          </v-btn>
        </v-col>
      </v-row>
      <!-- Antropometrias -->
      <v-row>
        <v-col cols="12" lg="12">
          <v-data-table
            dense
            :items="antropometriasAdulto"
            :headers="colunasAntropometrias"
            :items-per-page="10"
            :sort-by.sync="ordenacaoTabelaAntropometria"
            :search="search"
            class="elevation-0"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-left">
                    <v-chip :color="obterCor(item.classificacao)" label small>{{
                      item.classificacao
                    }}</v-chip>
                  </td>
                  <td class="text-center">{{ item.peso }} Kg</td>
                  <td class="text-center">
                    {{ item.altura }}
                  </td>
                  <td class="text-center">
                    {{ item.imc }}
                  </td>
                  <td class="text-right">
                    {{ item.dataMarcacao | data }}
                  </td>
                  <td class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          @click="editarAntropometria(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <edit-2-icon size="1.1x"></edit-2-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="excluir(item)"
                        >
                          <trash-icon size="1.1x"></trash-icon>
                        </v-btn>
                      </template>
                      <span>Excluir</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Cadastrar Antropometria -->
    <!-- TODO organiza campos na tela -->
    <v-dialog
      eager
      max-width="550px"
      persistent
      v-model="modalAdicionarAntropometria"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">
            {{
              modoEdicao
                ? "Editar avaliação antropométrica"
                : "Nova avaliação antropométrica"
            }}
          </h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-form ref="form" v-model="formValido">
            <v-row class="pt-3">
              <v-col cols="12" lg="12">
                <h2 class="pa-3 text-center">
                  Preencha as informações abaixo para realizar uma avaliação
                  antropométrica
                </h2>
              </v-col>
              <v-col cols="12" sm="6">
                <date-picker
                  label="Data marcação"
                  data-minima="1900-01-01"
                  autofocus
                  :dataMaxima="new Date().toString()"
                  dense
                  :rules="campoObrigatorio"
                  required
                  v-model="antropometriaAdulto.dataMarcacao"
                >
                </date-picker>
              </v-col>
              <v-col cols="12" sm="3">
                <CampoPeso
                  label="Peso"
                  dense
                  v-model="antropometriaAdulto.peso"
                  suffix="Kg"
                  required
                  :rules="campoObrigatorio"
                  class="input-numero alinhar-direita"
                >
                </CampoPeso>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  input-mode="decimal"
                  label="Altura"
                  v-model="antropometriaAdulto.altura"
                  v-mask="'#.##'"
                  :rules="campoObrigatorio"
                  dense
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!modoEdicao"
            class="btn-primario"
            text
            @click="cadastrarAntropometria"
            :disabled="!formValido"
          >
            Salvar
          </v-btn>
          <v-btn
            v-if="modoEdicao"
            class="btn-primario"
            text
            @click="atualizarAntropometria"
            :disabled="!formValido"
          >
            Atualizar
          </v-btn>
          <v-btn
            @click="fecharModalAdicionarAntropometria"
            class="btn-terciario"
            text
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <janela-confirmacao
      v-model="abrirJanelaConfirmacao"
      :callbackPrimario="callbackExcluirantropometria"
      :mensagem="mensagemDeExclusao"
    />
  </v-card>
</template>

<script>
// Componentes
import CampoPeso from "@/components/comum/CampoPeso";
import DatePicker from "@/components/comum/Datepicker";
import JanelaConfirmacao from "@/components/comum/JanelaConfirmacao";
import TelaVazia from "@/components/comum/TelaVazia";

// Models
import AntropometriaAdultoModel from "@/model/antropometria/antropometria-adulto-model";

// Utils
import utilsCalculos from "@/utils/calculos";
import utilsData from "@/utils/data";

// API
import apiAntropometriaAdulto from "@/api/antropometria/antropometria-adulto-api";

// Mixin
import MixinMensagem from "@/mixin/mixin-mensagem";
import { CORES } from "@/constants/geral-constantes";

import moment from "moment";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "AntropometriaAdultoTab",
  mixins: [MixinMensagem],
  components: {
    CampoPeso,
    DatePicker,
    JanelaConfirmacao,
    TelaVazia,
  },
  props: {
    paciente: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      formValido: true,
      campoObrigatorio: [(v) => !!v || "Informação obrigatória"],
      mensagemDeExclusao: undefined,
      abrirJanelaConfirmacao: false,
      antropometriasAdulto: [],
      modalAdicionarAntropometria: false,
      modoEdicao: false,
      antropometriaAdulto: new AntropometriaAdultoModel(),
      search: "",
      cores: CORES,
      ordenacaoTabelaAntropometria: "dataMarcacao",
      colunasAntropometrias: [
        {
          text: "Classificação",
          value: "classificacao",
          sortable: true,
          align: "left",
        },
        { text: "Peso", value: "peso", sortable: true, align: "center" },
        { text: "Altura", value: "altura", sortable: true, align: "center" },
        { text: "IMC", value: "imc", sortable: false, align: "center" },
        {
          text: "Data de Marcação",
          value: "dataMarcacao",
          sortable: true,
          align: "right",
        },
        { text: "", value: "botoes", sortable: false, align: "right" },
      ],
      antropometriaAdultoExclusao: undefined,
    };
  },
  methods: {
    obterCor(classificacao) {
      switch (classificacao) {
        case (classificacao = "Baixo peso"):
          return "#FFECB3";
        case (classificacao = "Peso normal"):
          return "#C8E6C9";
        case (classificacao = "Sobrepeso"):
          return "#B3E5FC";
        case (classificacao = "Obesidade Grau I"):
          return "#FFCDD2";
        case (classificacao = "Obesidade Grau II"):
          return "#E1BEE7";
        case (classificacao = "Obesidade Grau III"):
          return "#9E9E9E";
      }
    },
    adicionarAntropometria() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria_adulto",
        nome_botao: "btn_adicionar_antropometria_adulto",
        paciente_id: this.$route.params.id,
      });
      this.modoEdicao = false;
      this.$refs.form.reset();
      this.modalAdicionarAntropometria = true;
      this.limparAntropometria();
    },

    editarAntropometria(antropometria) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria_adulto",
        nome_botao: "btn_editar_antropometria_adulto",
        paciente_id: this.$route.params.id,
      });
      antropometria = Object.assign({}, antropometria);
      this.antropometriaAdulto = new AntropometriaAdultoModel(antropometria);
      this.modoEdicao = true;
      this.modalAdicionarAntropometria = true;
    },

    fecharModalAdicionarAntropometria() {
      this.modalAdicionarAntropometria = false;
      this.limparAntropometria();
    },
    fecharConfirmacaoExclusao() {
      this.abrirJanelaConfirmacao = false;
      this.antropometriaAdultoExclusao = undefined;
    },

    limparAntropometria() {
      this.antropometriaAdulto = new AntropometriaAdultoModel();
    },

    cadastrarAntropometria() {
      this._calcularIMCAntropometria();

      this.antropometriaAdulto.consulta = this.$store.state.consulta;
      this.antropometriaAdulto.paciente = this.paciente;

      this._classificarAntropometria(this.antropometriaAdulto.imc);

      apiAntropometriaAdulto
        .cadastrar(this.antropometriaAdulto)
        .then((resposta) => {
          this.antropometriasAdulto.push(
            new AntropometriaAdultoModel(resposta.data)
          );
          this.dispararMensagem(
            "Antropometria cadastrada com sucesso",
            "success"
          );
          this.atualizarAntropometriaNaLista(
            new AntropometriaAdultoModel(resposta.data)
          );
          this.fecharModalAdicionarAntropometria();
        })
        .catch((erro) => {
          this.dispararMensagem(erro, "error");
        });
    },

    atualizarAntropometria() {
      this.$refs.form.validate();
      this._calcularIMCAntropometria();
      this._classificarAntropometria(this.antropometriaAdulto.imc);
      this.antropometriaAdulto.paciente = this.paciente;

      apiAntropometriaAdulto
        .atualizar(this.antropometriaAdulto)
        .then((resposta) => {
          this.dispararMensagem(
            "Antropometria atualizada com sucesso",
            "success"
          );
          this.atualizarAntropometriaNaLista(
            new AntropometriaAdultoModel(resposta.data)
          );
          this.fecharModalAdicionarAntropometria();
        })
        .catch((erro) => {
          this.dispararMensagem(erro, "error");
        });
    },

    _calcularIMCAntropometria() {
      this.antropometriaAdulto.peso = parseFloat(this.antropometriaAdulto.peso);
      this.antropometriaAdulto.altura = parseFloat(
        this.antropometriaAdulto.altura
      );

      this.antropometriaAdulto.imc = utilsCalculos.calcularImc(
        this.antropometriaAdulto.peso,
        this.antropometriaAdulto.altura
      );
    },

    _classificarAntropometria(imc) {
      if (imc <= 18.5) {
        this.antropometriaAdulto.classificacao = "Baixo peso";
      } else if (imc >= 18.6 && imc <= 24.9) {
        this.antropometriaAdulto.classificacao = "Peso normal";
      } else if (imc >= 25.0 && imc <= 29.9) {
        this.antropometriaAdulto.classificacao = "Sobrepeso";
      } else if (imc >= 30.0 && imc <= 34.9) {
        this.antropometriaAdulto.classificacao = "Obesidade Grau I";
      } else if (imc >= 35.0 && imc <= 39.9) {
        this.antropometriaAdulto.classificacao = "Obesidade Grau II";
      } else {
        this.antropometriaAdulto.classificacao = "Obesidade Grau III";
      }
    },

    atualizarAntropometriaNaLista(antropometria, deletarDaLista) {
      var index = this.antropometriasAdulto
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(antropometria.id));
      !deletarDaLista
        ? this.antropometriasAdulto.splice(index, 1, antropometria) // Atualiza
        : this.antropometriasAdulto.splice(index, 1); // Deleta
    },

    obterAntropometriasPaciente() {
      if (!this.paciente.id) {
        return;
      }
      apiAntropometriaAdulto
        .obterAntropometriaPorPaciente(this.paciente.id)
        .then((resposta) => {
          this.antropometriasAdulto = resposta.data.map(
            (a) => new AntropometriaAdultoModel(a)
          );
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem:
              "Ocorreu um erro ao obter as antropometrias deste paciente",
            tipo: "error",
          });
        });
    },

    excluir(antropometria) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria_adulto",
        nome_botao: "btn_excluir_antropometria_adulto",
        paciente_id: this.$route.params.id,
      });
      antropometria.paciente = this.paciente;
      this.antropometriaAdultoExclusao = antropometria;
      this.abrirJanelaConfirmacao = true;

      this.mensagemDeExclusao = `Deseja excluir a antropometria com a data de marcação do dia&nbsp;<b>${utilsData.aplicarMascaraEmDataIso(
        antropometria.dataMarcacao
      )}</b>?`;
    },

    callbackExcluirantropometria() {
      apiAntropometriaAdulto
        .deletar(this.antropometriaAdultoExclusao)
        .then(() => {
          this.dispararMensagem(
            "Antropometria excluida com sucesso",
            "success"
          );
          this.atualizarAntropometriaNaLista(
            this.antropometriaAdultoExclusao,
            true
          );
          this.fecharConfirmacaoExclusao();
        })
        .catch((erro) => {
          this.dispararMensagem(erro, "error");
          this.fecharConfirmacaoExclusao();
        })
        .finally(() => {
          this.fecharConfirmacaoExclusao();
        });
    },
  },
  watch: {
    paciente() {
      this.obterAntropometriasPaciente();
    },
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },
  computed: {},
  mounted() {
    this.obterAntropometriasPaciente();
  },
};
</script>

<style lang="scss" scoped>
</style>