<template>
  <div>
    <v-card flat v-if="calculos.length">
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12">
            <v-btn
              class="btn-primario mr-2"
              text
              @click="abrirModalAdicionarCalculo"
            >
              <plus-icon size="1x" class="mr-2"></plus-icon>
              Novo Cálculo
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <v-data-table
              dense
              :items="calculos"
              :headers="colunasCalculoComputed"
              :items-per-page="10"
              sort-by="id"
              sort-desc
              class="elevation-0"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-left">
                      {{ item.fatorAtividade }}
                    </td>
                    <td class="text-left">
                      {{ item.adicionalEnergetico }}
                    </td>
                    <td class="text-left">
                      {{ item.criadoEm | data }}
                    </td>
                    <td class="text-left">
                      {{ item.valorEnergeticoTotal | numeroTresCasasDecimais }}
                      kcal
                    </td>
                    <td v-if="paciente.sexo == 'F'" class="text-left">
                      {{ item.semanaGestacional }}
                    </td>
                    <td class="text-right">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="abrirModalEditarCalculo(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <edit-2-icon size="1.1x"></edit-2-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="excluir(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <trash-icon size="1.1x"></trash-icon>
                          </v-btn>
                        </template>
                        <span>Excluir</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row v-else>
      <TelaVazia
        :imagemSrc="'calculo-energetico.svg'"
        :titulo="'Não há cálculo energético para este paciente'"
        :textoBotaoPrimario="'Adicionar cálculo energético'"
        :exibirBotaoPrimario="true"
        :eventoBotaoPrimario="abrirModalAdicionarCalculo"
      >
      </TelaVazia>
    </v-row>
    <!-- Cadastrar Cálculo -->
    <v-dialog width="450px" v-model="modalAdicionarCalculo">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Cálculo Energético</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="12">
              <v-form id="cadastro" ref="form" validation v-model="formValido">
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      label="Idade"
                      dense
                      v-model="calculo.idade"
                      type="number"
                      class="alinhar-direita"
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <CampoPeso
                      label="Peso"
                      dense
                      v-model="calculo.peso"
                      suffix="Kg"
                      class="input-numero alinhar-direita"
                      :rules="campoObrigatorio"
                    >
                    </CampoPeso>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <v-autocomplete
                      label="Selecione uma fórmula"
                      :items="listaFórmulas"
                      item-text="nome"
                      return-object
                      dense
                      v-model="formula"
                      clearable
                      :rules="campoObrigatorio"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <v-autocomplete
                      label="Adicional Energético"
                      :items="listaAdicionalEnergético"
                      dense
                      v-model="calculo.adicionalEnergetico"
                      clearable
                      item-text="texto"
                      item-value="valor"
                      :rules="campoNumericoObrigatorio"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <v-subheader class="pa-0"
                      >Selecione o fator de atividade</v-subheader
                    >
                    <br />
                    <v-slider
                      v-model="fatorAtividade"
                      min="140"
                      max="240"
                      step="10"
                      always-dirty
                      :thumb-color="corSlider"
                      thumb-label="always"
                      :tick-labels="faixaExercicio"
                      tick-size="2"
                      :thumb-size="25"
                    >
                      <template v-slot:thumb-label="{}">
                        {{ thumbLabel }}
                      </template>
                    </v-slider>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              v-if="!edicao"
              text
              class="btn-primario mr-2"
              @click="criarCalculoEnergetico"
              :disabled="!formValido"
            >
              Salvar
            </v-btn>
            <v-btn
              v-else-if="edicao"
              text
              class="btn-primario mr-2"
              @click="editarCalculo"
              :disabled="!formValido"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-secundario"
              @click="modalAdicionarCalculo = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// OUTROS
import moment from "moment";
import {
  CALCULOSENERGETICOS,
  ADICIONALENERGETICO,
} from "@/constants/formulas-constants";
import CampoPeso from "@/components/comum/CampoPeso";
import TelaVazia from "@/components/comum/TelaVazia";

// MODEL
import CalculoEnergeticoModel from "@/model/consulta/calculo-energetico-model";
import GestacaoModel from "@/model/gestante/gestacao-model";
import AntropometriaModel from "@/model/gestante/antropometria-model";

// API
import apiCalculoEnergetico from "@/api/consulta/calculos-energeticos-api";
import apiGestacao from "@/api/gestante/gestacao-api";
import apiAntropometria from "@/api/gestante/antropometria-api";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "CalculoEnergeticoTab",
  components: {
    CampoPeso,
    TelaVazia,
  },
  props: {
    paciente: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: {
    "excluir-calculo": (evento) => {
      return !!(evento.callback && evento.mensagem);
    },
    "disparar-mensagem": (evento) => {
      return !!(evento.mensagem && evento.tipo);
    },
    "fechar-janela-confirmacao": undefined,
  },
  data() {
    return {
      calculos: [],
      modalAdicionarCalculo: false,
      calculo: {},
      calculoExclusao: undefined,
      formValido: false,
      campoObrigatorio: [(v) => !!v || "Este campo é obrigatório"],
      campoNumericoObrigatorio: [
        (v) => !isNaN(parseInt(v)) || "Este campo é obrigatório",
      ],
      listaFórmulas: CALCULOSENERGETICOS,
      listaAdicionalEnergético: ADICIONALENERGETICO,
      fatorAtividade: 140,
      formula: {},
      edicao: false,
      faixaExercicio: ["1.4", "", "", "", "", "", "", "", "", "", "2.4"],
      colunasCalculo: [
        {
          text: "Fator Atividade",
          value: "fatorAtividade",
          sortable: true,
          align: "left",
          mostrar: true,
        },
        {
          text: "Adicional Energético",
          value: "adicionalEnergetico",
          sortable: true,
          align: "left",
          mostrar: true,
        },
        {
          text: "Data",
          value: "criadoEm",
          sortable: true,
          align: "left",
          mostrar: true,
        },
        {
          text: "VET",
          value: "valorEnergeticoTotal",
          sortable: true,
          align: "left",
          mostrar: true,
        },
        {
          text: "Semana Gestacional",
          value: "semanaGestacional",
          sortable: true,
          align: "left",
          mostrar: this.paciente.sexo == "F",
        },
        {
          text: "",
          value: "botoes",
          align: "right",
          sortable: false,
          mostrar: true,
        },
      ],
      pesoPreGestacional: undefined,
      semanaGestacional: undefined,
    };
  },

  methods: {
    async abrirModalAdicionarCalculo() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "calculo_energetico",
        nome_botao: "btn_adicionar_calculo_energetico",
        paciente_id: this.$route.params.id,
      });
      this.$refs.form && (await this.$refs.form.reset());
      let idade = moment().diff(this.paciente.dataNascimento, "years");
      this.calculo.idade = idade;
      this.calculo.peso = this.pesoPreGestacional;
      this.calculo.semanaGestacional = this.semanaGestacional;
      this.edicao = false;
      this.modalAdicionarCalculo = true;
    },
    obterDadosGestacionais() {
      if (this.paciente.sexo == "M") {
        return undefined;
      }

      apiGestacao
        .obterPaciente(this.paciente.id)
        .then((resposta) => {
          let gestacoes = resposta.data.map((g) => new GestacaoModel(g));
          let gestacao = gestacoes.find((g) => g.dataFimGestacao == null);

          if (gestacao) {
            this.obterPesoPreGestacional(gestacao.id);

            //TODO: mover esta conta para um utils de calculos
            let dataAtual = new Date().getTime();
            let dataMenstruacao = new Date(
              gestacao.dataUltimaMenstruacao
            ).getTime();

            let totalDeDias = dataAtual - dataMenstruacao;

            //Converte dia em milisegundo em dia
            totalDeDias = (totalDeDias / 1000 / 60 / 60 / 24).toFixed(0);

            //Converte dias em semanas gestacionais
            this.semanaGestacional = Math.round(totalDeDias / 7).toString();
          }
        })
        .catch(() => {});
    },
    obterPesoPreGestacional(idGestacao) {
      apiAntropometria
        .obterAntropometriaPorGestacao(idGestacao)
        .then((resposta) => {
          let antropometrias = resposta.data.map(
            (a) => new AntropometriaModel(a)
          );
          let antropometria = antropometrias.find(
            (a) => a.semanaGestacional == 0
          );
          this.pesoPreGestacional = parseFloat(antropometria.peso);
          this.calculo.peso = this.pesoPreGestacional;
        })
        .catch(() => {});
    },
    criarCalculoEnergetico() {
      this.calculo.fatorAtividade = this.fatorAtividade / 100;
      this.calculo.paciente = this.paciente;
      this.calculo.consulta = this.$store.state.consulta;
      this.formula.calculo(this.calculo);
      this.calculo.formula = this.formula.nome;
      this.calculo = new CalculoEnergeticoModel(this.calculo);

      apiCalculoEnergetico
        .cadastrar(this.calculo)
        .then((resposta) => {
          this.calculos.push(new CalculoEnergeticoModel(resposta.data));
          this.modalAdicionarCalculo = false;
          this.$emit("disparar-mensagem", {
            mensagem: "Cálculo criado com sucesso!",
            tipo: "success",
          });
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Erro ao criar o cálculo",
            tipo: "error",
          });
        });
    },
    obterCalculosEnergeticos() {
      if (this.calculos[0]) {
        return;
      }

      apiCalculoEnergetico
        .obterCalculoPorPaciente(this.paciente.id)
        .then((resposta) => {
          this.calculos = resposta.data.map(
            (c) => new CalculoEnergeticoModel(c)
          );
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Não foi possível obter os cálculos",
            tipo: "error",
          });
        });
    },

    async abrirModalEditarCalculo(calculo) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "calculo_energetico",
        nome_botao: "btn_editar_calculo_energetico",
        paciente_id: this.$route.params.id,
      });
      this.$refs.form && (await this.$refs.form.reset());
      this.calculo = Object.assign({}, calculo);
      this.fatorAtividade = this.calculo.fatorAtividade * 100;
      this.calculo.adicionalEnergetico = parseInt(
        this.calculo.adicionalEnergetico
      );
      this.formula = this.listaFórmulas.find(
        (f) => f.nome === this.calculo.formula
      );
      this.edicao = true;
      this.modalAdicionarCalculo = true;
    },
    editarCalculo() {
      this.calculo.fatorAtividade = this.fatorAtividade / 100;
      apiCalculoEnergetico
        .atualizar(this.calculo)
        .then((resposta) => {
          let indice = this.calculos.findIndex((c) => c.id == this.calculo.id);
          this.calculos.splice(
            indice,
            1,
            new CalculoEnergeticoModel(resposta.data)
          );
          this.modalAdicionarCalculo = false;
          this.$emit("disparar-mensagem", {
            mensagem: "Cálculo atualizado com sucesso!",
            tipo: "success",
          });
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Erro ao editar o cálculo",
            tipo: "error",
          });
        });
    },
    excluir(calculo) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "calculo_energetico",
        nome_botao: "btn_excluir_calculo_energetico",
        paciente_id: this.$route.params.id,
      });
      this.calculoExclusao = calculo.id;
      this.$emit("excluir-calculo", {
        callback: this.callbackExcluirCalculo,
        mensagem: `Deseja excluir o cálculo energético?`,
      });
    },
    callbackExcluirCalculo() {
      apiCalculoEnergetico
        .deletar(this.calculoExclusao)
        .then(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Cálculo excluído com sucesso",
            tipo: "success",
          });
          let indice = this.calculos.findIndex(
            (c) => c.id == this.calculoExclusao
          );
          this.calculos.splice(indice, 1);
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Falha na exclusão do cálculo",
            tipo: "error",
          });
        })
        .finally(() => {
          this.$emit("fechar-janela-confirmacao");
          this.calculoExclusao = undefined;
        });
    },
  },

  computed: {
    corSlider() {
      if (this.fatorAtividade < 170) return "#8AE6B9";
      if (this.fatorAtividade < 200) return "#FFDD80";
      return "#FF8080";
    },
    thumbLabel() {
      return this.fatorAtividade / 100;
    },
    colunasCalculoComputed() {
      return this.colunasCalculo.filter((c) => c.mostrar);
    },
  },

  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY HH:mm");
    },
    numeroTresCasasDecimais(valor) {
      let numero = parseFloat(valor);
      if (isNaN(numero)) {
        return "";
      }
      return numero.toFixed(3);
    },
  },

  mounted() {
    this.obterCalculosEnergeticos();
    this.obterDadosGestacionais();
  },
};
</script>

<style lang="scss" scoped>
.input-numero::v-deep input[type="number"],
.input-numero::v-deep input::-webkit-outer-spin-button,
.input-numero::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.alinhar-direita::v-deep input {
  text-align: right;
}
</style>