<template>
  <v-row justify="center">
    <v-dialog v-model="abrirJanela" persistent max-width="500">
      <v-card>
        <v-card-title v-html="mensagem" class="mensagem">
          
        </v-card-title>
        <v-card-text class="text-center">
          <v-btn
            text
            @click="callbackPrimario()"
            class="btn-primario mr-3"
            >
            {{ textoBotaoPrimario }}
          </v-btn>
          <v-btn
            text
            class="btn-secundario"
            @click="fecharJanela"
            >
            {{ textoBotaoSecundario }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  name: "AlteracaoStatus",
  model: {
    prop: "abrirJanela",
    event: "onChange",
  },
  data() {
    return {
      janelaAberta: this.abrirJanela,
    };
  },
  props: {
    mensagem: { type: String, default: "Confirma a alteração do status?" },
    textoBotaoPrimario: { type: String, default: "Sim" },
    textoBotaoSecundario: { type: String, default: "Não" },
    abrirJanela: { type: Boolean, default: false },
    callbackPrimario: { type: Function, default: () => {} },
    // callbackSecundario: { type: Function, default: ()=> {}},
    idBotaoPrimario: { type: String, default: "" },
    idBotaoSecundario: { type: String, default: "" },
  },

  methods: {
    fecharJanela() {
      this.janelaAberta = false;
      this.$emit("onChange", this.janelaAberta);
    },

  },
};
</script>
<style scoped>
.mensagem{
  text-align: center;
  margin: 0 auto;
  display: block;
  padding: 20px;
  word-break: normal;
}

div.v-card__text {
  margin-top: 15px;
  text-align: end;
}
.v-card__title .mensagem{
  font-size: 10px;
  font-weight: 200;
  text-align: center!important;
}
</style>
