<template>
  <v-row class="mt-12">
    <v-col class="text-center mt-12" cols="12" col="12">
      <img
        class="logo"
        src="../../assets/img/sucesso.png"
        alt="icone de sucesso"
      />
      <h1 class="texto-confirmacao">Cadastro efetuado com sucesso!</h1>

      <p class="mensagem">Clique no botão abaixo e acesse sua conta.</p>
      <v-btn
        class="btn-primario mt-4"
        color="primary"
        elevation="0"
        @click="direcionarTelaLogin"
      >
        Acesse agora
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "ConfirmacaoAssinatura",
  data() {
    return {};
  },
  methods: {
    direcionarTelaLogin() {
      mixpanel.track("click", {
        nome_pagina: "assinatura_confirmacao",
        nome_botao: "btn_acesse_agora",
      });

      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    mixpanel.track("page_view", { nome_pagina: "assinatura_confirmacao" });
  },
};
</script>
<style lang="scss" scoped>
.confirmacao-assinatura {
  display: flex;
  justify-content: center;
}
.logo {
  width: 5%;
}
.texto-confirmacao {
  color: $cor_primaria;
  font-weight: 400;
}
</style>