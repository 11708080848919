var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-5"},[_c('v-snackbar',{attrs:{"vertical":false,"timeout":_vm.timeout,"top":"top","color":_vm.tipo,"elevation":"0","multi-line":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("OK")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.mensagem)+" ")]),(_vm.loading)?_c('div',{staticClass:"loading text-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":5,"color":"primary","indeterminate":""}}),_c('h1',{staticClass:"loading-titulo"},[_vm._v("Carregando")])],1):_vm._e(),(!_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h1',[_vm._v("Controle de Usuarios")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('v-btn',{staticClass:"btn-primario ml-0",attrs:{"text":""},on:{"click":_vm.adicionarUsuario}},[_c('v-icon',{staticClass:"mr-2 icone"},[_vm._v("mdi-plus")]),_vm._v(" Adicionar")],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card',{staticClass:"card-cuitatto"},[_c('v-data-table',{attrs:{"headers":_vm.colunas,"items":_vm.usuarios,"search":_vm.search,"sort-by":"id","sort-desc":"","items-per-page":10},scopedSlots:_vm._u([{key:"item.criadoEm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("data")(item.criadoEm))+" ")]}},{key:"item.dataInativacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("data")(item.dataInativacao))+" ")]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"coluna-botoes text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.editarUsuario(item)}}},'v-btn',attrs,false),on),[_c('edit-2-icon',{staticClass:"custom-class",attrs:{"size":"1.3x"}})],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(item.idPerfil != 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.excluirUsuario(item)}}},'v-btn',attrs,false),on),[_c('trash-icon',{staticClass:"custom-class",attrs:{"size":"1.3x"}})],1)]}}],null,true)},[_c('span',[_vm._v("Excluir ou Inativar")])]):_vm._e()],1)]}}],null,false,2803763302)})],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","min-height":"800px","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('h1',{staticClass:"titulo-modal"},[_vm._v(" "+_vm._s(!_vm.modoEdicao ? "Adicionar " : "Editar ")+" usuário ")])]),_c('v-card-text',[_c('v-divider'),_c('v-container',[_c('form',{attrs:{"autocomplete":"off"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Nome","autocomplete":"off","dense":""},model:{value:(_vm.usuario.nome),callback:function ($$v) {_vm.$set(_vm.usuario, "nome", $$v)},expression:"usuario.nome"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"E-mail","autocomplete":"off","type":"email","dense":""},model:{value:(_vm.usuario.email),callback:function ($$v) {_vm.$set(_vm.usuario, "email", $$v)},expression:"usuario.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.perfils,"label":"Perfil","item-text":"descricao","return-object":"","clearable":"","autocomplete":"off","dense":""},model:{value:(_vm.usuario.perfil),callback:function ($$v) {_vm.$set(_vm.usuario, "perfil", $$v)},expression:"usuario.perfil"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Senha","append-icon":_vm.value ? 'visibility_off' : 'visibility',"type":_vm.value ? 'password' : 'text',"rules":[
                    function (v) { return v.trim().length >= 6 ||
                      'Senha deve ter mínimo de 6 caracteres'; } ],"dense":""},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.usuario.senha),callback:function ($$v) {_vm.$set(_vm.usuario, "senha", $$v)},expression:"usuario.senha"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('date-picker',{attrs:{"label":"Data de inativação","hint":"Opcional","dense":"","dataMinima":_vm.dataMinima},model:{value:(_vm.usuario.dataInativacao),callback:function ($$v) {_vm.$set(_vm.usuario, "dataInativacao", $$v)},expression:"usuario.dataInativacao"}})],1)],1)],1)])],1),_c('v-card-actions',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"btn-primario mr-2",attrs:{"text":"","disabled":!this.usuario.modeloValido() || _vm.desabilitarBotoes},on:{"click":_vm.salvar}},[_vm._v(" Salvar ")]),_c('v-btn',{staticClass:"btn-secundario",attrs:{"text":""},on:{"click":_vm.cancelar}},[_vm._v(" Cancelar ")])],1)],1)],1)],1),_c('janela-confirmacao',{attrs:{"callbackPrimario":_vm.callbackConfirmarExclusao,"mensagem":_vm.mensagemDeExclusao},on:{"onChange":_vm.limparCampos},model:{value:(_vm.abrirJanelaConfirmacao),callback:function ($$v) {_vm.abrirJanelaConfirmacao=$$v},expression:"abrirJanelaConfirmacao"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }