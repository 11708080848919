<template>
  <v-text-field
    :id="id ? id : undefined"
    :readonly="readonly"
    :label="label"
    :rules="rules"
    :dense="dense"
    :suffix="suffix"
    v-model.lazy="valor"
    @change="emitirAlteracoes"
    @keypress="filtraNaoNumerosETamanho"
    @keyup="mascaraPeso"
    :autofocus="autofocus"
    validate-on-blur
    ref="campoTexto"
  >
  </v-text-field>
</template>

<script>
export default {
  name: "CampoPeso",
  model: {
    prop: "peso",
    event: "change",
  },
  props: {
    autofocus: { type: Boolean, default: false },
    peso: {
      type: Number,
    },
    label: {
      type: String,
      required: false,
      default: "Peso",
    },
    id: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [(v) => (v && v.length <= 7) || `Peso incorreto.`],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: "kg",
    },
  },

  data: () => ({
    valor: undefined,
  }),

  watch: {
    peso(novoValor) {
      this.setNovoValorManualmente(novoValor);
    },
    autofocus(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.campoTexto.focus();
          this.onFocus();
        });
      }
    },
  },

  methods: {
    onFocus() {
      this.$emit("onFocus");
    },

    setNovoValorManualmente(valor) {
      if (!valor) {
        this.$refs.campoTexto.$el.getElementsByTagName("input")[0].value =
          this.valor = valor;
        return;
      }

      this.$refs.campoTexto.$el.getElementsByTagName("input")[0].value =
        this.valor = parseFloat(valor).toFixed(3);
    },

    emitirAlteracoes() {
      this.setNovoValorManualmente(parseFloat(this.valor));
      this.$emit("change", parseFloat(this.valor));
    },

    filtraNaoNumerosETamanho(evento) {
      /**
       * TODO: Melhorar esse metodo, fiz esse de forma funcional para efetuar testes,
       */
      if (evento.keyCode == 46 && this.valor.toString().indexOf(".") != -1) {
        evento.preventDefault();
      }

      if (evento.keyCode != 46) {
        if (evento.keyCode < 48 || evento.keyCode > 57) {
          evento.preventDefault();
        } else if (this.valor?.length == 7) {
          evento.preventDefault();
        } else {
          let valor = this.valor.toString().split(".");

          if (valor.length == 1 && valor[0].length == 3) {
            evento.preventDefault();
          } else if (valor[0].length > 3) {
            evento.preventDefault();
          }
        }
      } else if (this.valor?.length == 7) {
        evento.preventDefault();
      }
    },

    mascaraPeso() {
      if (!this.valor) {
        return;
      }

      return this.valor;
    },
  },
};
</script>