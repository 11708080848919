import PacienteModel from '../paciente/paciente-model';
import ConsultaModel from './consulta-model';

export default class CalculoEnergeticoModel {
    constructor(obj){
        obj = obj || {};
        this.id = obj.id;
        this.paciente = new PacienteModel(obj.paciente);
        this.consulta = new ConsultaModel(obj.consulta);
        this.idade = obj.idade;
        this.peso = obj.peso;
        this.fatorAtividade = obj.fatorAtividade;
        this.formula = obj.formula;
        this.adicionalEnergetico = obj.adicionalEnergetico;
        this.taxaMetabolicaBasal = obj.taxaMetabolicaBasal;
        this.valorEnergeticoTotal = obj.valorEnergeticoTotal;
        this.semanaGestacional = obj.semanaGestacional;
        this.dataInativacao = obj.dataInativacao;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
    }

    modeloValidoParaCadastro(){
        let erros = [];
        this._validacao(erros);
        return erros;
    }

    modeloValidoParaAtualizacao(){
        let erros = [];
        this._validacao(erros);
        return erros;
    }

    _validacao(erros){
        let valido = this.paciente && this.paciente.id &&
            this.idade && 
            this.peso &&
            this.fatorAtividade &&
            this.formula &&
            this.taxaMetabolicaBasal &&
            this.valorEnergeticoTotal;

        if(!valido){
            erros.push(`Os campos idade, peso, fatorAtividade e fórmula são obrigatórios.`);
        }
    }

}