var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"vertical":false,"timeout":_vm.timeout,"top":"top","color":_vm.tipo,"elevation":"0","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" OK ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.mensagem)+" ")]),_c('v-navigation-drawer',{attrs:{"app":"","expand-on-hover":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('MenuStatic')],1),_c('v-app-bar',{attrs:{"color":"white","elevation":"0","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('img',{staticClass:"pa-2",attrs:{"id":"logotipo-barra-topo","src":require("@/assets/svg/logotipo.svg"),"height":"50px"}}),_c('v-toolbar-title'),_c('v-spacer'),_c('CadastroConsultaModal'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('settings-icon')],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}])},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',{on:{"click":function($event){return _vm.alterarSenha()}}},[_c('key-icon',{staticClass:"mr-2",attrs:{"size":"1.4x"}}),_vm._v(" Alterar Senha ")],1),_c('v-list-item',{on:{"click":function($event){return _vm.sair()}}},[_c('log-out-icon',{staticClass:"mr-2",attrs:{"size":"1.2x"}}),_vm._v(" Sair ")],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"300px"},model:{value:(_vm.janelaAlterarSenha),callback:function ($$v) {_vm.janelaAlterarSenha=$$v},expression:"janelaAlterarSenha"}},[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("Alterar Senha")])]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-5"},[_c('v-text-field',{attrs:{"label":"Nova Senha","required":"","append-icon":_vm.value ? 'visibility_off' : 'visibility',"type":_vm.value ? 'password' : 'text',"autofocus":""},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.usuario.novaSenha),callback:function ($$v) {_vm.$set(_vm.usuario, "novaSenha", $$v)},expression:"usuario.novaSenha"}}),_c('v-text-field',{attrs:{"label":"Confirme sua senha","required":"","append-icon":_vm.value ? 'visibility_off' : 'visibility',"type":_vm.value ? 'password' : 'text'},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.usuario.novaSenhaConfirma),callback:function ($$v) {_vm.$set(_vm.usuario, "novaSenhaConfirma", $$v)},expression:"usuario.novaSenhaConfirma"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-primario",attrs:{"text":""},on:{"click":function($event){return _vm.redefinirSenha()}}},[_vm._v("Salvar ")]),_c('v-btn',{staticClass:"btn-terciario",attrs:{"text":""},on:{"click":function($event){return _vm.fechar()}}},[_vm._v("Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }