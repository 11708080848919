import PacienteModel  from '../paciente/paciente-model';
import ConsultaModel from '../consulta/consulta-model';

export default class GestacaoModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.paciente = new PacienteModel(obj.paciente);
    this.dataUltimaMenstruacao = obj.dataUltimaMenstruacao;
    this.dataFimGestacao = obj.dataFimGestacao;
    
    this.imc = obj.imc;
    this.altura = obj.altura;
    this.peso = obj.peso;
    this.consulta = new ConsultaModel(obj.consulta);

    this.dataInativacao = obj.dataInativacao;
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
  }

  modeloValido(){
      return !!(this.id && this.imc && this.dataUltimaMenstruacao);
  }
}