'use strict';

import variaveisPaciente from './variaveis-paciente.js';

function obterVariaveisSistema(paciente){
  paciente = paciente || {};
  
  return [].concat(
    variaveisPaciente.obter(paciente), 
  );

}

export default { obterVariaveisSistema };