
function obter(paciente) {
  return [
    {
      nome: "Paciente",
      filhas: [
        {
          nome: "Identificador",
          atributo: "Id_Paciente",
          titulo: "Identificador do paciente",
          inline: true,
          conteudo: () => {
            return paciente.id || '';
          }
        },
        {
          nome: "Nome",
          atributo: "Nome_Paciente",
          titulo: "Nome do paciente",
          inline: true,
          conteudo: () => {
            return paciente.nome || '';
          }
        },
        // {
        //   nome: "Data de Nascimento",
        //   atributo: "Data_De_Nascimento",
        //   titulo: "Data de Nascimento",
        //   inline: true,
        //   conteudo: () => {
        //     return paciente.dataNascimento && Mascara.aplicarMascaraEmDataIso(paciente.dataNascimento) || '';
        //   }
        // },
      ]
    }
  ]
}

export default { obter }