<template>
  <div>
    <v-card flat class="sem-registro" v-if="!this.anamneses.length">
      <v-card-text>
        <img
          src="@/assets/img/figuras/anamnese.svg"
          width="300"
          alt="Imagem anamnese inexistente"
        />
        <h1 class="titulo-sem-registro">Não há anamneses para este paciente</h1>
        <p v-if="!$store.state.consulta.id" class="subtitulo-sem-registro">
          Inicie uma consulta e crie sua primeira anamnese
        </p>
        <v-btn
          v-else
          text
          class="btn-primario"
          @click="adicionarAnamnese"
          :disabled="
            !(
              !$store.state.anamnese.id &&
              $store.state.consulta.id &&
              $store.state.consulta.paciente.id == $route.params.id
            )
          "
        >
          Adicionar anamnese
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card flat v-else>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="7">
            <v-alert
              dense
              text
              border="left"
              type="info"
              color="primary"
              v-if="!$store.state.consulta.id"
            >
              Para criar uma anamnese para este paciente, inicie uma consulta.
            </v-alert>
            <v-btn
              v-else
              class="btn-secundario"
              text
              @click="adicionarAnamnese"
              :disabled="
                !(
                  !$store.state.anamnese.id &&
                  $store.state.consulta.id &&
                  $store.state.consulta.paciente.id == $route.params.id
                )
              "
            >
              <plus-icon size="1x" class="mr-2"></plus-icon>
              Anamnese
            </v-btn>
          </v-col>
          <v-col cols="12" lg="5">
            <h1>Últimas Anamneses</h1>
          </v-col>
        </v-row>
        <!-- anamneses -->
        <v-row class="linha">
          <v-col cols="12" lg="7">
            <v-data-table
              dense
              :items="anamneses"
              :headers="colunasanamneses"
              :items-per-page="10"
              class="elevation-0"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-center">
                      {{ item.criadoEm | data }}
                    </td>
                    <td class="text-left">
                      {{ item.consulta.procedimento.descricao }}
                    </td>
                    <td class="text-left">
                      {{ item.consulta.classificacao.descricao }}
                    </td>
                    <td class="text-right">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="imprimirAnamnese(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <printer-icon size="1.5x"></printer-icon>
                          </v-btn>
                        </template>
                        <span>Imprimir</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="visualizarAnamnese(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <eye-icon size="1.5x"></eye-icon>
                          </v-btn>
                        </template>
                        <span>Visualizar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
          <v-col class="historico" cols="12" lg="5">
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="anamnese in anamnesesTimeline"
                :key="anamnese.key"
                small
                color="#C29879"
              >
                <v-card color="#C29879">
                  <v-card-title class="timeline-card-topo">
                    <span class="timeline-topo-chave">Consulta: </span
                    >{{ anamnese.criadoEm | data }} <br />
                    <span class="timeline-topo-chave">Procedimento: </span
                    >{{ anamnese.consulta.procedimento.descricao }} <br />
                    <span class="timeline-topo-chave">Classificação: </span
                    >{{ anamnese.consulta.classificacao.descricao }} <br />
                  </v-card-title>
                  <v-card-text
                    v-html="anamnese.descricao"
                    class="timeline-card-texto white text--primary"
                  ></v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Modal visualização -->
    <v-dialog
      v-model="modalVisualizarAnamnese"
      persistent
      scrollable
      min-height="600px"
      max-width="980px"
    >
      <v-card flat>
        <v-card-title>
          <h1 class="titulo-modal">Anamnese</h1>
        </v-card-title>
        <v-card-subtitle class="subtitulo-modal">
          Consulta: {{ anamnese.criadoEm | data }} <br />
          Procedimento: {{ anamnese.consulta.procedimento.descricao }} <br />
          Classificação: {{ anamnese.consulta.classificacao.descricao }} <br />
        </v-card-subtitle>
        <v-card-text>
          <div class="texto-modal" v-html="anamnese.descricao"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn text class="btn-primario mr-2" @click="imprimirAnamnese">
              Imprimir
            </v-btn>
            <v-btn
              text
              class="btn-secundario"
              @click="modalVisualizarAnamnese = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// OUTROS
import moment from "moment";
import templateImpressao from "@/constants/template-impressao-constantes";

// UTILS
import utilsImpressao from "@/utils/impressao";

// API
import apiAnamnese from "@/api/consulta/anamneses-api";

// MODEL
import AnamneseModel from "@/model/consulta/anamnese-model";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "AnamneseTab",
  props: {
    paciente: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  emits: {
    "disparar-mensagem": (evento) => {
      return !!(evento.mensagem && evento.tipo);
    },
  },
  data() {
    return {
      anamneses: [],
      anamnese: new AnamneseModel(),
      modalVisualizarAnamnese: false,
      colunasanamneses: [
        {
          text: "Data",
          value: "criadoEm",
          sortable: true,
          align: "center",
        },
        {
          text: "Procedimento",
          value: "consulta.procedimento.descricao",
          sortable: true,
          align: "left",
        },
        {
          text: "Classificação",
          value: "consulta.classificacao.descricao",
          sortable: true,
          align: "left",
        },
        {
          text: "",
          value: "botoes",
          align: "right",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    adicionarAnamnese() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anamnese",
        nome_botao: "btn_adicionar_anamnese",
        paciente_id: this.$route.params.id,
      });
      let anamnese = new AnamneseModel();
      anamnese.consulta.id = this.$store.state.consulta.id;
      anamnese.paciente.id = this.$store.state.consulta.paciente.id;
      apiAnamnese
        .cadastrar(anamnese)
        .then((resposta) => {
          anamnese = new AnamneseModel(resposta.data);
          this.$store.commit("criarAnamnese", anamnese);
          this.$eventBus.$emit("nova-anamnese");
          this.anamneses.unshift(anamnese);
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Ocorreu um erro ao adicionar a anamnese",
            tipo: "error",
          });
        });
    },
    visualizarAnamnese(anamnese) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anamnese",
        nome_botao: "btn_visualizar_anamnese",
        paciente_id: this.$route.params.id,
      });
      this.anamnese = anamnese;
      this.modalVisualizarAnamnese = true;
    },
    obterAnamnesesPaciente() {
      if (!this.paciente.id) {
        return;
      }
      apiAnamnese
        .obterAnamnesePorPaciente(this.paciente.id)
        .then((resposta) => {
          this.anamneses = resposta.data.map((a) => new AnamneseModel(a));
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Ocorreu um erro ao obter as anamneses deste paciente",
            tipo: "error",
          });
        });
    },
    consultaEncerrada() {
      if (this.$store.state.anamnese.id) {
        this.atualizarAnamnese();
        this.$store.commit("limparAnamnese");
      }
    },
    imprimirAnamnese(anamnese) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anamnese",
        nome_botao: "btn_imprimir_anamnese",
        paciente_id: this.$route.params.id,
      });
      if (!this.modalVisualizarAnamnese) {
        this.anamnese = anamnese;
      }
      let html = templateImpressao.templateImpressaoAnamnese(this.anamnese);
      utilsImpressao.imprimir(html);
      if (!this.modalVisualizarAnamnese) {
        this.anamnese = new AnamneseModel();
      }
    },
    atualizarAnamnese() {
      let anamneseAtualizada = this.$store.state.anamnese;
      let indice = this.anamneses.findIndex(
        (a) => a.id == anamneseAtualizada.id
      );
      if (!isNaN(indice)) {
        this.anamneses.splice(indice, 1, anamneseAtualizada);
      }
    },
  },
  watch: {
    paciente() {
      this.obterAnamnesesPaciente();
    },
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },
  computed: {
    anamnesesTimeline() {
      return this.anamneses.slice(0, 4);
    },
  },
  mounted() {
    this.obterAnamnesesPaciente();
    this.$eventBus.$on("consulta-encerrada", this.consultaEncerrada);
    this.$eventBus.$on("anamnese-atualizada", this.atualizarAnamnese);
  },
};
</script>

<style lang="scss" scoped>
.texto-modal {
  color: black;
}
.titulo-modal {
  font-weight: 400;
  padding-bottom: 5px;
}
.subtitulo-modal {
  font-weight: 500;
}
.timeline-card-topo {
  background-color: $cor_primaria;
  color: $branco;
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
  display: inline-block;
  padding: 10px;
}

.timeline-card-texto {
  padding: 10px;
}

.timeline-topo-chave {
  font-weight: 500;
}

.linha {
  min-height: 100vh;
}

.historico {
  position: absolute;
  padding-bottom: 50px;
  right: 0;
  height: 100%;
  overflow: auto;
}

@media only screen and (max-width: 1263px) {
  .historico {
    overflow: auto;
    position: inherit;
  }
}
</style>