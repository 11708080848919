<template>
  <v-main class="fundo">
    <v-container class="mt-6">
      <div class="assinatura">
        <v-snackbar
          v-model="snackbar"
          :vertical="false"
          :timeout="timeout"
          top="top"
          :color="tipo"
          elevation="5"
          multi-line
          right
          shaped
        >
          {{ mensagem }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
          </template>
        </v-snackbar>
        <v-card class="box" elevation="10">
          <div class="logo">
            <img
              src="../../assets/svg/logotipo.svg"
              alt="Logotipo"
              width="50%"
            />
          </div>
          <v-row class="mt-5">
            <v-col cols="12" sm="12">
              <div class="texto-plano text-center py-5">
                <p>
                  Você selecionou o plano
                  <strong>{{ plano.DESCRICAO }}, </strong>
                  faça seu cadastro agora e aproveite
                  <strong> 30 dias grátis.</strong>
                </p>
              </div>
            </v-col>

            <v-col cols="12" sm="12">
              <h3 class="titulo-cadastro">Informações cadastrais</h3>
              <v-divider class="pb-3"></v-divider>
            </v-col>

            <v-col cols="12" sm="8">
              <v-text-field
                dense
                color="primary"
                label="Nome completo"
                v-model="assinante.nome"
                :rules="[(v) => !!v || 'Nome completo é obrigatório']"
                persistent-hint
                hint="Este campo é obrigatório"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                dense
                inputmode="decimal"
                color="primary"
                label="Celular"
                v-mask="'(##) #####-####'"
                v-model="assinante.celular"
                :rules="[(v) => !!v || 'Celular é obrigatório']"
                persistent-hint
                hint="Este campo é obrigatório"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-autocomplete
                @change="alterarTipoPessoa"
                dense
                :items="tipoPessoa"
                label="Tipo de cadastro"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                dense
                inputmode="decimal"
                color="primary"
                :label="assinante.pessoaFisica ? 'CPF' : 'CNPJ'"
                v-mask="
                  assinante.pessoaFisica
                    ? '###.###.###-##'
                    : '##.###.###/####-##'
                "
                v-model="assinante.cpfOuCnpj"
                :rules="[
                  (v) => !!v || `Este campo é obrigatório`,
                  (v) =>
                    (!!v && v.length === 14) ||
                    (v && v.length === 18) ||
                    `Informe um documento válido`,
                ]"
                required
                persistent-hint
                hint="Este campo é obrigatório"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                dense
                color="primary"
                label="Cupom de desconto"
                v-model="assinante.cupomDesconto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <h3 class="titulo-cadastro">Informações de acesso</h3>
              <v-divider class="pb-3"></v-divider>
            </v-col>

            <v-col cols="12" sm="5">
              <v-text-field
                dense
                color="primary"
                label="E-mail"
                v-model="assinante.email"
                :rules="[
                  (v) => !!v || 'E-mail é obrigatório',
                  (v) =>
                    !v ||
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'Informe um e-mail válido',
                ]"
                required
                persistent-hint
                hint="Este campo é obrigatório"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                dense
                @keyup.enter="logar"
                label="Crie sua Senha"
                v-model="assinante.senha"
                :append-icon="value ? 'visibility_off' : 'visibility'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
                :rules="[
                  (v) => !!v || `Este campo é obrigatório`,
                  (v) =>
                    (!!v && v.length > 5) ||
                    `Informe uma senha de mínimo 6 caracteres`,
                ]"
                required
                persistent-hint
                hint="Este campo é obrigatório"
              ></v-text-field>
            </v-col>

            <!-- Termos -->
            <v-col cols="12" sm="12">
              <v-checkbox v-model="assinante.aceitaOsTermos">
                <template v-slot:label>
                  <div>
                    Li e concordo com os
                    <a @click="termo = true" href="#" class="primary--text">
                      termos de uso e privacidade
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </v-col>

            <v-col cols="12" col="12">
              <v-btn
                class="btn-terciario float-right"
                elevation="0"
                text
                @click="direcionarSite()"
                >Cancelar</v-btn
              >
              <v-btn
                class="btn-primario float-right mr-3"
                color="primary"
                elevation="0"
                :disabled="!ativoParaAssinatura"
                @click="assinarPlano"
                >Assinar agora</v-btn
              >
            </v-col>
          </v-row>
        </v-card>

        <!-- Termo de responsabilidade -->
        <div>
          <v-dialog
            v-model="termo"
            fullscreen
            transition="dialog-bottom-transition"
          >
            <v-card class="pa-10">
              <v-toolbar dark flat color="primary">
                <v-btn icon dark @click="recursarOstermos()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title
                  >Termo de uso e responsabilidade</v-toolbar-title
                >
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <DetalhesTermo></DetalhesTermo>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-btn
                class="btn-primario mb-5 ml-5"
                color="primary"
                elevation="0"
                @click="aceitarOsTermos()"
                >Aceito os termos</v-btn
              >
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-container>
  </v-main>
</template>
<script>
import DetalhesTermo from "./DetalhesTermo.vue";
import AssinanteModel from "@/model/geral/assinante-model";
import mixinMensagen from "@/mixin/mixin-mensagem";
import assinanteApi from "@/api/assinante/assinante-api";

// Tracking
import mixpanel from "mixpanel-browser";

// Apis
import apiViaCEP from "@/api/geral/cep-api";
import apiIBGE from "@/api/geral/ibge-api";

import utilsValidacaoCpfECnpj from "@/utils/validadorCpfOuCnpj";
import { LISTA_PLANOS } from "@/constants/geral-constantes";

export default {
  name: "login",
  mixins: [mixinMensagen],
  components: {
    DetalhesTermo,
  },
  data() {
    return {
      termo: false,
      loading: false,
      tipoPessoa: ["Pessoa Física", "Pessoa Jurídica"],
      assinante: new AssinanteModel(),
      planos: LISTA_PLANOS,
      value: "password",
      planoEscolhido: {},
      site: "http://hml.cuitatto.com.br/", // Colocar aqui o endereço do site.
      plano: {},
      listaDeCidades: [],
      listaDeUFs: [],
      listaDeEstados: [],
    };
  },

  computed: {
    ativoParaAssinatura() {
      return this.assinante.ativoParaAssinatura();
    },
  },

  mounted() {
    mixpanel.track("page_view", { nome_pagina: "assinatura" });
    this.obterUFs();
    let dadosParaAssinatura = this.$router.history.current.query;

    // Não pode cair aqui de forma alguma.
    if (!dadosParaAssinatura.plano) {
      this.direcionarSite();
      return;
    }

    this.plano = LISTA_PLANOS.find((p) => p.ID == dadosParaAssinatura.plano);

    // Não pode cair aqui de forma alguma.
    if (!this.plano.ID) {
      this.direcionarSite();
      return;
    }

    this.assinante.idPlano = this.plano.ID;
  },

  methods: {
    chamarJanelaConfirmacao() {
      this.$router.push({ name: "ConfirmacaoAssinatura" });
    },

    alterarTipoPessoa(escolha) {
      this.assinante.pessoaFisica = escolha == "Pessoa Física";
    },

    aceitarOsTermos() {
      this.assinante.aceitaOsTermos = true;
      this.termo = false;
    },

    recursarOstermos() {
      this.assinante.aceitaOsTermos = false;
      this.termo = false;
    },

    limparCampos() {
      this.assinante = new AssinanteModel();
    },

    direcionarSite() {
      mixpanel.track("click", {
        nome_pagina: "assinatura",
        nome_botao: "btn_cancelar_assinatura",
      });
      window.open(this.site, "_self");
    },
    removerMascaraCelular(celular) {
      if (!celular) {
        return 0;
      }

      return celular
        .toString()
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("-", "")
        .replaceAll(" ", "")
        .trim();
    },

    removerMascaraCpfOuCnpj(cpfOuCnpj) {
      if (!cpfOuCnpj) {
        return 0;
      }

      return cpfOuCnpj
        .toString()
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", "")
        .replaceAll(" ", "")
        .trim();
    },

    assinarPlano() {
      mixpanel.track("click", {
        nome_pagina: "assinatura",
        nome_botao: "btn_assinar_plano",
        assinante_plano: this.assinante.idPlano,
        assinante_nome: this.assinante.nome,
        assinante_email: this.assinante.email,
        assinante_celular: this.assinante.celular,
      });
      let resultado = true;
      let mensagem = undefined;
      this.tirarMascara();

      if (this.assinante.pessoaFisica) {
        resultado = utilsValidacaoCpfECnpj.validarCPF(this.assinante.cpfOuCnpj);
        mensagem = "Informe um CPF válido.";
      } else {
        resultado = utilsValidacaoCpfECnpj.validarCNPJ(
          this.assinante.cpfOuCnpj
        );
        mensagem = "Informe um CNPJ válido.";
      }

      if (!resultado) {
        this.dispararMensagem(mensagem, "error");
        return;
      }

      let assinante = new AssinanteModel(this.assinante);
      assinante.celular = this.removerMascaraCelular(assinante.celular);
      assinante.cpfOuCnpj = this.removerMascaraCpfOuCnpj(assinante.cpfOuCnpj);
      console.log(assinante);
      this.loading = true;
      assinanteApi
        .cadastrar(assinante)
        .then((resposta) => {
          if (!resposta.data.id) {
            throw new Error(
              "Não foi posível efetuar seu cadastro. Entre em contato com nossa equipe técnica através do e-mail suporte@cuitatto.com.br"
            );
          }
          this.loading = false;
          this.chamarJanelaConfirmacao();
        })
        .catch((error) => {
          this.loading = false;
          this.dispararMensagem(error.response.data.mensagem, "error");
        });
    },

    obterEnderecoPorPeloCEP() {
      let cep = this.assinante.cep
        .toString()
        .replaceAll(".", "")
        .replaceAll("-", "");

      apiViaCEP
        .obterEnderecoPorCep(cep)
        .then((resposta) => {
          this.obterObterCidadesPorUF(resposta.data.uf);
          this.assinante.rua = resposta.data.logradouro;
          this.assinante.cidade = resposta.data.localidade;
          this.assinante.bairro = resposta.data.bairro;
          this.assinante.complemento = resposta.data.complemento;
          this.assinante.estado = resposta.data.uf;
        })
        .catch(/* Não fazer nada */);
    },

    obterUFs() {
      apiIBGE.obterEstados().then((resposta) => {
        this.listaDeEstados = resposta.data.map((uf) => uf.sigla);
      });
    },

    obterObterCidadesPorUF(uf) {
      apiIBGE.obterMunicipios(uf).then((resposta) => {
        this.listaDeCidades = resposta.data.map((c) => c.nome);
      });
    },

    tirarMascara() {
      this.assinante.celular =
        this.assinante.celular &&
        this.assinante.celular
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", "");
      this.assinante.cep =
        this.assinante.cep && this.assinante.cep.replace("-", "");
      this.assinante.cpf =
        this.assinante.cpf &&
        this.assinante.cpf.replaceAll(".", "").replace("-", "");
      this.assinante.rg =
        this.assinante.rg &&
        this.assinante.rg.replaceAll(".", "").replace("-", "");
    },
  },
};
</script>
<style lang="scss" scoped>
#cupom-de-desconto {
  background-color: #eeeeee;
  padding: 20px;
  width: 90%;
  border-radius: 10px;
  border: 1px dashed #d0d0d0;
  margin: 0 auto;
  text-align: center;
}

.texto-assinatura {
  font-size: 1.2em;
}
.box {
  border-radius: 10px;
  padding: 25px;
}
.assinatura {
  max-width: 700px;
  margin: auto;
  /* margin-left: -55px; */
}
.logo {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
a:hover {
  text-decoration: underline !important;
}
.fundo {
  background: linear-gradient(
      rgba(194, 152, 122, 0.651),
      rgba(206, 179, 160, 0.651)
    ),
    url(../../assets/img/bg-login.jpg) !important;
  background-size: cover !important;
}
.plano-escolhido {
  width: 80%;
  margin: 0 auto;
  background-color: #00a82d3a;
  border: 1px solid #00a82d;
  border-radius: 10px;
  // box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
  padding: 20px;
}
.texto-plano p {
  font-size: 1.5em;
}
.janela-resposta {
  padding: 40px;
  .mensagem {
    font-size: 1.4em;
    padding-bottom: 20px;
  }
  .titulo {
    font-size: 2em;
    font-weight: 600;
    padding: 20px 0;
  }
}
.titulo-cadastro {
  font-weight: 500;
  font-size: 1.3em;
}
</style>
