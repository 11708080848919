const CALCULOSENERGETICOS = [
    {
        nome: 'FAO/WHO/ONU(2004)',
        calculo: (calculoEnergetico) => {            
            if (calculoEnergetico.idade <= 30) {
                calculoEnergetico.taxaMetabolicaBasal = (calculoEnergetico.peso * 14.818) + 486.6;
            }
            else {
                calculoEnergetico.taxaMetabolicaBasal = (calculoEnergetico.peso * 8.126) + 845.6;
            }
            
            calculoEnergetico.valorEnergeticoTotal = (calculoEnergetico.taxaMetabolicaBasal * calculoEnergetico.fatorAtividade) + calculoEnergetico.adicionalEnergetico;
        }
    }
]

const ADICIONALENERGETICO = [
    {
        texto: '0 - Sem adicional energético',
        valor: 0
    },
    {
        texto: '85 - Indicado para 1º trimestre',
        valor: 85
    },
    {
        texto: '142 - Indicado para 2º trimestre',
        valor: 142
    },
    {
        texto: '285 - Indicado para 2º trimestre',
        valor: 258
    },
    {
        texto: '237 - Indicado para 3º trimestre',
        valor: 237
    },
    {
        texto: '475 - Indicado para 3º trimestre',
        valor: 475
    },
]





export{
	CALCULOSENERGETICOS,
    ADICIONALENERGETICO
}